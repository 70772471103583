
::v-deep .button-box .el-button {
    width: 94px;
    height: 30px;
    line-height: 0;
    border-radius: 15px;
}

.editor {
    height: 100vh;
    position: relative;

    .button-box {
        position: absolute;
        right: 162px;
        padding-top: 18px;
    }


    #container {
        background: #ccc;
        height: 100%;
        width: 100%;
        border: none;
    }

    .library-box {
        ::v-deep .el-dialog__footer {
            text-align: center;
            background: #211D40;
        }

        ::v-deep .el-dialog__header {
            background: #120E2D;
        }

        ::v-deep .el-dialog__title {
            color: #ffffff;
            font-size: 18px;
        }

        ::v-deep .el-dialog__body {
            background: #211D40;
        }

        ::v-deep .el-dialog__footer .el-button {
            width: 90px;
            height: 30px;
            line-height: 0;
            border-radius: 15px;
        }

        .d-mainBox {
            display: flex;
            flex-direction: row;

            .left-box {
                min-width: 50%;
            }

            .right-box {
                min-width: 40%;

                .row1 {
                    display: flex;
                    min-height: 90px;
                    width: 100%;

                    ::v-deep span .el-input__inner {
                        background: #211D40;
                        border-top-width: 0px;
                        border-left-width: 0px;
                        border-right-width: 0px;
                        border-bottom-width: 1px;
                    }

                    .img-box {
                        width: 100%;
                        height: 300px;

                        img {
                            max-height: 100%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
